// @ts-ignore
import {Elm} from './Main.elm';

navigator.serviceWorker.register(new URL('worker.ts', import.meta.url));

const seed: number | null = process.env.NODE_ENV === 'production' ?
    Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) :
    null;

const app = Elm.Main.init({
    node: document.getElementById('main'),
    flags: {seed},
});

let currentRequestId = 0;
app.ports.notifyMutate.subscribe(async () => {
    currentRequestId += 1;
    const myRequestId = currentRequestId;
    await new Promise(res => setTimeout(res, 1000));
    if (currentRequestId === myRequestId) {
        app.ports.requestAutoSave.send(null);
    }
});

const dbPromise = new Promise<IDBDatabase>((res, rej) => {
    const result = indexedDB.open("MythicGME_2e", 1);
    result.onsuccess = function () {
        res(result.result);
    }
    result.onerror = rej;
    result.onupgradeneeded = function (event) {
        const db: IDBDatabase = (event.target as any).result;
        const saves = db.createObjectStore("saves", {keyPath: "id"});
        saves.createIndex("id", "id", {unique: true});
    }
});

type Save = { id: number, time: number, data: object | null };
app.ports.requestModel.subscribe(async (slot: number) => {
    const db = await dbPromise;
    const txn = db.transaction(["saves"]);
    const saves = txn.objectStore("saves");
    const save: Save | undefined = await new Promise((res, rej) => {
        const req: IDBRequest<Save> = saves.get(slot);
        req.onsuccess = () => res(req.result);
        req.onerror = rej;
    });
    if (save !== undefined) {
        app.ports.loadModel.send(save.data);
    }
});

const sendSaveInfo = async () => {
    const db = await dbPromise;
    const txn = db.transaction(["saves"]);
    const saves = txn.objectStore("saves");
    const saveInfos: Record<number, { createdAt: number }> = {};
    await new Promise((res, rej) => {
        const req = saves.openCursor();
        req.onsuccess = () => {
            const cursor = req.result;
            if (cursor) {
                saveInfos[Number(cursor.key)] = {createdAt: cursor.value.time};
                cursor.continue();
            } else {
                res(null);
            }
        };
        req.onerror = rej;
    });
    const slots = [];
    for (let i = 1; i <= 20; i += 1) {
        slots.push(saveInfos[i] || null);
    }
    app.ports.loadSaveInfo.send({slots});
};

app.ports.requestSaveInfo.subscribe(sendSaveInfo);

app.ports.saveModel.subscribe(async (save: [number, object | null]) => {
    const slot = save[0];
    const data = save[1];
    const db = await dbPromise;
    const txn = db.transaction(["saves"], "readwrite");
    const saves = txn.objectStore("saves");
    if (data === null) {
        await saves.delete(slot);
    } else {
        await saves.put({id: slot, time: Date.now(), data} as Save);
    }
    await sendSaveInfo();
});