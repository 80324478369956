port module Main exposing (main)

import Browser
import FateChart
import Html exposing (Html)
import Html.Attributes
import Html.Events
import Json.Decode as Decode exposing (Decoder)
import Json.Decode.Pipeline exposing (required)
import Json.Encode as Encode
import List.Extra
import Random
import Strftime
import StringUtil as StringUtil
import Table exposing (Table)
import Task
import Time



-- MAIN


type alias Flags =
    { seed : Maybe Int
    }


main : Program Flags Model Msg
main =
    Browser.element
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        }



-- PORTS


port saveModel : ( Int, Encode.Value ) -> Cmd msg


port requestModel : Int -> Cmd msg


port notifyMutate : () -> Cmd msg


port loadModel : (Encode.Value -> msg) -> Sub msg


port requestAutoSave : (() -> msg) -> Sub msg


port requestSaveInfo : () -> Cmd msg


port loadSaveInfo : (Encode.Value -> msg) -> Sub msg



-- MODEL


type Tool
    = MeaningTables
    | AdventureLists
    | AdventureManagement
    | SaveDataLoading
    | SaveManagement SaveInfo


type alias Event =
    { eventType : String
    , value : String
    }


type alias Model =
    { currentTool : Tool
    , eventLog : List Event
    , randomSeed : Random.Seed
    , threadsInput : String
    , charactersInput : String
    , threadsList : List String
    , charactersList : List String
    , chaosFactor : Int
    , timeZone : Maybe Time.Zone
    }


type alias SaveInfo =
    { slots : List (Maybe SaveSlotInfo)
    }


type alias SaveSlotInfo =
    { createdAt : Int }


init : Flags -> ( Model, Cmd Msg )
init flags =
    ( { currentTool = AdventureManagement
      , eventLog = []
      , randomSeed = Random.initialSeed <| Maybe.withDefault 42 flags.seed
      , threadsInput = ""
      , charactersInput = ""
      , threadsList = []
      , charactersList = []
      , chaosFactor = 5
      , timeZone = Nothing
      }
    , Cmd.batch [ requestModel 0, Task.perform SetTimeZone Time.here ]
    )



-- UPDATE


type Msg
    = NoOp
    | RollOnTable String String String Random.Seed
    | SwitchTool Tool
    | UpdateAdventureListInput AdventureListType String
    | AddToList AdventureListType
    | RemoveFromList AdventureListType Int
    | UpdateChaosFactor Bool
    | AskFateQuestion FateChart.Odds
    | GenerateRandomEvent
    | TestExpectedScene
    | Save Int
    | InitiateLoad Int
    | Load Decode.Value
    | Delete Int
    | SetTimeZone Time.Zone
    | LoadSaveInfo Decode.Value
    | Reset


type AdventureListType
    = Threads
    | Characters


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        NoOp ->
            ( model, Cmd.none )

        RollOnTable tableName item1 item2 newSeed ->
            let
                event =
                    { eventType = "Roll " ++ StringUtil.toTitleCase tableName
                    , value = StringUtil.toTitleCase item1 ++ " " ++ StringUtil.toTitleCase item2
                    }
            in
            ( { model | eventLog = event :: model.eventLog, randomSeed = newSeed }
            , notifyMutate ()
            )

        SwitchTool tool ->
            ( { model | currentTool = tool }
            , if tool == SaveDataLoading then
                requestSaveInfo ()

              else
                Cmd.none
            )

        UpdateAdventureListInput listType input ->
            case listType of
                Threads ->
                    ( { model | threadsInput = input }, Cmd.none )

                Characters ->
                    ( { model | charactersInput = input }, Cmd.none )

        AddToList listType ->
            let
                ( newModel, success ) =
                    case listType of
                        Threads ->
                            if model.threadsInput |> String.trim |> String.isEmpty |> not then
                                ( { model | threadsList = String.trim model.threadsInput :: model.threadsList, threadsInput = "" }, True )

                            else
                                ( model, False )

                        Characters ->
                            if model.charactersInput |> String.trim |> String.isEmpty |> not then
                                ( { model | charactersList = String.trim model.charactersInput :: model.charactersList, charactersInput = "" }, True )

                            else
                                ( model, False )
            in
            if success then
                let
                    listName =
                        case listType of
                            Threads ->
                                "Threads"

                            Characters ->
                                "Characters"

                    event =
                        { eventType = "Add to " ++ listName ++ " List"
                        , value =
                            case listType of
                                Threads ->
                                    model.threadsInput

                                Characters ->
                                    model.charactersInput
                        }
                in
                ( { newModel | eventLog = event :: newModel.eventLog }
                , notifyMutate ()
                )

            else
                ( newModel, Cmd.none )

        RemoveFromList listType index ->
            let
                ( newModel, removedItem ) =
                    case listType of
                        Threads ->
                            ( { model | threadsList = List.Extra.removeAt index model.threadsList }
                            , List.Extra.getAt index model.threadsList
                            )

                        Characters ->
                            ( { model | charactersList = List.Extra.removeAt index model.charactersList }
                            , List.Extra.getAt index model.charactersList
                            )
            in
            case removedItem of
                Just item ->
                    let
                        listName =
                            case listType of
                                Threads ->
                                    "Threads"

                                Characters ->
                                    "Characters"

                        event =
                            { eventType = "Remove from " ++ listName ++ " List"
                            , value = item
                            }
                    in
                    ( { newModel | eventLog = event :: newModel.eventLog }
                    , notifyMutate ()
                    )

                Nothing ->
                    ( newModel, Cmd.none )

        UpdateChaosFactor increment ->
            let
                newChaosFactor =
                    if increment then
                        model.chaosFactor + 1

                    else
                        model.chaosFactor - 1

                clampedNewChaosFactor =
                    min (max 1 newChaosFactor) 9

                event =
                    { eventType = "Update Chaos Factor"
                    , value = String.fromInt model.chaosFactor ++ " ➡️ " ++ String.fromInt clampedNewChaosFactor
                    }
            in
            if clampedNewChaosFactor == model.chaosFactor then
                ( model, Cmd.none )

            else
                ( { model
                    | chaosFactor = clampedNewChaosFactor
                    , eventLog = event :: model.eventLog
                  }
                , notifyMutate ()
                )

        AskFateQuestion odds ->
            let
                ( outcome, shouldSpawnRandomEvent, newSeed1 ) =
                    FateChart.roll model.chaosFactor odds model.randomSeed

                outcomeStr =
                    case outcome of
                        FateChart.Yes ->
                            "Yes"

                        FateChart.No ->
                            "No"

                        FateChart.ExceptionalYes ->
                            "Exceptional Yes"

                        FateChart.ExceptionalNo ->
                            "Exceptional No"

                event =
                    { eventType = "Fate Question (" ++ FateChart.oddsToString odds ++ ")"
                    , value = outcomeStr
                    }

                ( randomEventList, newSeed2 ) =
                    if shouldSpawnRandomEvent then
                        spawnRandomEvent { model | randomSeed = newSeed1 } |> Tuple.mapFirst (\e -> [ { eventType = "Random Event", value = e } ])

                    else
                        ( [], newSeed1 )
            in
            ( { model | eventLog = randomEventList ++ event :: model.eventLog, randomSeed = newSeed2 }
            , notifyMutate ()
            )

        GenerateRandomEvent ->
            let
                ( event, newSeed ) =
                    spawnRandomEvent model
            in
            ( { model | eventLog = { eventType = "Random Event", value = event } :: model.eventLog, randomSeed = newSeed }
            , notifyMutate ()
            )

        TestExpectedScene ->
            let
                ( roll, newSeed1 ) =
                    Random.step (Random.int 1 10) model.randomSeed

                ( eventValue, newSeed2 ) =
                    if roll <= model.chaosFactor then
                        if modBy 2 roll == 0 then
                            spawnRandomEvent { model | randomSeed = newSeed1 }
                                |> Tuple.mapFirst (\evt -> "Random Event - " ++ evt)

                        else
                            ( "Altered Scene", newSeed1 )

                    else
                        ( "Expected Scene", newSeed1 )
            in
            ( { model
                | eventLog = { eventType = "Test Expected Scene", value = eventValue } :: model.eventLog
                , randomSeed = newSeed2
              }
            , notifyMutate ()
            )

        Save slot ->
            ( model, saveModel ( slot, modelEncoder model ) )

        InitiateLoad slot ->
            ( model, requestModel slot )

        Load json ->
            ( case Decode.decodeValue (modelDecoder model) json of
                Ok newModel ->
                    newModel

                Err err ->
                    { model
                        | eventLog = { eventType = "Failed Load", value = Decode.errorToString err } :: model.eventLog
                    }
            , Cmd.none
            )

        Delete slot ->
            ( model, saveModel ( slot, Encode.null ) )

        SetTimeZone zone ->
            ( { model | timeZone = Just zone }, Cmd.none )

        LoadSaveInfo json ->
            let
                handleInfo =
                    case model.currentTool of
                        SaveManagement _ ->
                            True

                        SaveDataLoading ->
                            True

                        _ ->
                            False
            in
            if handleInfo then
                ( case Decode.decodeValue saveInfoDecoder json of
                    Ok info ->
                        { model | currentTool = SaveManagement info }

                    Err err ->
                        { model
                            | eventLog = { eventType = "Failed to Load Save Info", value = Decode.errorToString err } :: model.eventLog
                        }
                , Cmd.none
                )

            else
                ( model, Cmd.none )

        Reset ->
            let
                ( newSeed, _ ) =
                    Random.step (Random.int 0 (2 ^ 32 - 1)) model.randomSeed

                ( newModel, _ ) =
                    init { seed = Just newSeed }
            in
            ( { newModel | currentTool = SaveDataLoading }, requestSaveInfo () )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.batch
        [ loadModel Load
        , requestAutoSave (\_ -> Save 0)
        , loadSaveInfo LoadSaveInfo
        ]



-- VIEW


view : Model -> Html Msg
view model =
    Html.div [ Html.Attributes.class "min-h-screen bg-gray-100 dark:bg-gray-800 p-4" ]
        [ menuBar model
        , Html.div [ Html.Attributes.class "flex flex-col md:flex-row md:space-x-4" ]
            [ Html.div [ Html.Attributes.class "md:w-3/4" ]
                [ toolView model ]
            , eventLogView model
            ]
        ]


menuBar : Model -> Html Msg
menuBar model =
    Html.div [ Html.Attributes.class "bg-gray-800 dark:bg-gray-900 text-white p-4 mb-4 rounded" ]
        [ Html.h1 [ Html.Attributes.class "text-lg font-bold" ] [ Html.text "Mythic GM Emulator 2e (Unofficial)" ]
        , Html.div [ Html.Attributes.class "flex space-x-4 mt-2 overflow-x-auto" ]
            [ toolButton model AdventureManagement "Adventure Management"
            , toolButton model AdventureLists "Adventure Lists"
            , toolButton model MeaningTables "Meaning Tables"
            , toolButton model SaveDataLoading "Save Management"
            ]
        ]


toolButton : Model -> Tool -> String -> Html Msg
toolButton model tool label =
    let
        isSelected =
            case model.currentTool of
                SaveManagement _ ->
                    case tool of
                        SaveManagement _ ->
                            True

                        SaveDataLoading ->
                            True

                        _ ->
                            False

                _ ->
                    model.currentTool == tool

        baseClasses =
            "cursor-pointer py-1 px-3 rounded hover:bg-gray-700 dark:hover:bg-gray-600 transition-colors duration-200"

        activeClasses =
            if isSelected then
                "bg-gray-600 dark:bg-gray-500 text-gray-100"

            else
                "text-gray-400"
    in
    Html.button
        [ Html.Attributes.class <| baseClasses ++ " " ++ activeClasses
        , Html.Events.onClick <| SwitchTool tool
        ]
        [ Html.text label ]


eventLogView : Model -> Html Msg
eventLogView model =
    Html.div [ Html.Attributes.class "bg-white dark:bg-gray-700 p-4 mb-4 md:mb-0 rounded shadow-lg md:w-1/4 md:h-[calc(100vh-10rem)] flex flex-col" ]
        [ Html.h2 [ Html.Attributes.class "text-lg font-bold mb-2 dark:text-gray-300" ] [ Html.text "Event Log" ]
        , Html.div [ Html.Attributes.class "flex-grow overflow-y-auto flex flex-col-reverse" ]
            [ Html.ul [ Html.Attributes.class "space-y-2 space-y-reverse flex flex-col-reverse pb-2" ]
                (List.map
                    (\event ->
                        Html.li [ Html.Attributes.class "text-sm" ]
                            [ Html.span [ Html.Attributes.class "inline-flex items-center px-3 py-1 rounded-l bg-indigo-500 dark:bg-indigo-400 text-white font-semibold" ]
                                [ Html.text event.eventType ]
                            , Html.span [ Html.Attributes.class "inline-flex items-center px-3 py-1 rounded-r bg-indigo-200 dark:bg-indigo-700 text-indigo-700 dark:text-indigo-200 font-semibold" ]
                                [ Html.text event.value ]
                            ]
                    )
                    model.eventLog
                )
            ]
        ]


toolView : Model -> Html Msg
toolView model =
    case model.currentTool of
        MeaningTables ->
            meaningTablesView model

        AdventureLists ->
            adventureListsView model

        AdventureManagement ->
            adventureManagementView model

        SaveDataLoading ->
            Html.div [] [ Html.text "Loading..." ]

        SaveManagement saveData ->
            saveManagementView model saveData


meaningTablesView : Model -> Html Msg
meaningTablesView model =
    Html.div [ Html.Attributes.class "bg-white dark:bg-gray-700 p-4 rounded shadow-lg" ]
        [ Html.h2 [ Html.Attributes.class "text-lg font-bold mb-2 dark:text-gray-300" ] [ Html.text "Meaning Tables" ]
        , Html.ul [ Html.Attributes.class "grid grid-cols-2 md:grid-cols-4 gap-4" ]
            (List.map
                (\table ->
                    Html.li
                        [ Html.Events.onClick (rollOnTable table model.randomSeed)
                        , Html.Attributes.class "cursor-pointer bg-gray-200 dark:bg-gray-600 rounded p-2 hover:bg-gray-300 dark:hover:bg-gray-500 dark:text-white transition-colors duration-200"
                        ]
                        [ Html.text (Table.name table |> StringUtil.toTitleCase) ]
                )
                Table.tables
            )
        ]


adventureListsView : Model -> Html Msg
adventureListsView model =
    Html.div [ Html.Attributes.class "bg-white dark:bg-gray-700 p-4 rounded shadow-lg" ]
        [ Html.h2 [ Html.Attributes.class "text-lg font-bold mb-2 dark:text-gray-300" ] [ Html.text "Adventure Lists" ]
        , Html.div [ Html.Attributes.class "flex flex-col md:flex-row md:space-x-4" ]
            [ Html.div [ Html.Attributes.class "w-full md:w-1/2" ]
                [ Html.h3 [ Html.Attributes.class "text-md font-semibold mb-2 dark:text-white" ] [ Html.text "Threads List" ]
                , Html.input
                    [ Html.Attributes.placeholder "Add a new thread"
                    , Html.Attributes.type_ "text"
                    , Html.Attributes.value model.threadsInput
                    , Html.Events.onInput (UpdateAdventureListInput Threads)
                    , Html.Attributes.class "w-full mb-2 p-2 border dark:bg-gray-800 dark:text-white dark:border-gray-600 rounded"
                    , onEnterPressed <| AddToList Threads
                    ]
                    []
                , Html.button
                    [ Html.Attributes.type_ "button"
                    , Html.Events.onClick <| AddToList Threads
                    , Html.Attributes.class "w-full mb-2 p-2 bg-blue-500 text-white rounded"
                    ]
                    [ Html.text "Add Thread" ]
                , Html.ul [ Html.Attributes.class "list-disc pl-5 space-y-2" ]
                    (List.map
                        (\( index, thread ) ->
                            Html.li [ Html.Attributes.class "text-sm dark:text-white flex justify-between items-center pr-4" ]
                                [ Html.text thread
                                , removeButton Threads index
                                ]
                        )
                        (List.indexedMap Tuple.pair model.threadsList)
                    )
                ]
            , Html.div [ Html.Attributes.class "w-full md:w-1/2" ]
                [ Html.h3 [ Html.Attributes.class "text-md font-semibold mb-2 dark:text-white" ] [ Html.text "Characters List" ]
                , Html.input
                    [ Html.Attributes.placeholder "Add a new character"
                    , Html.Attributes.type_ "text"
                    , Html.Attributes.value model.charactersInput
                    , Html.Events.onInput (UpdateAdventureListInput Characters)
                    , Html.Attributes.class "w-full mb-2 p-2 border dark:bg-gray-800 dark:text-white dark:border-gray-600 rounded"
                    , onEnterPressed <| AddToList Characters
                    ]
                    []
                , Html.button
                    [ Html.Attributes.type_ "button"
                    , Html.Events.onClick <| AddToList Characters
                    , Html.Attributes.class "w-full mb-2 p-2 bg-blue-500 text-white rounded"
                    ]
                    [ Html.text "Add Character" ]
                , Html.ul [ Html.Attributes.class "list-disc pl-5 space-y-2" ]
                    (List.map
                        (\( index, character ) ->
                            Html.li [ Html.Attributes.class "text-sm dark:text-white flex justify-between items-center pr-4" ]
                                [ Html.text character
                                , removeButton Characters index
                                ]
                        )
                        (List.indexedMap Tuple.pair model.charactersList)
                    )
                ]
            ]
        ]


removeButton : AdventureListType -> Int -> Html Msg
removeButton listType index =
    Html.button
        [ Html.Attributes.type_ "button"
        , Html.Events.onClick <| RemoveFromList listType index
        , Html.Attributes.class "text-red-500 text-xs ml-auto"
        ]
        [ Html.text "X" ]


adventureManagementView : Model -> Html Msg
adventureManagementView model =
    Html.div [ Html.Attributes.class "bg-white dark:bg-gray-700 p-4 rounded shadow-lg" ]
        [ Html.h2 [ Html.Attributes.class "text-lg font-bold mb-2 dark:text-gray-300" ] [ Html.text "Adventure Management" ]
        , Html.div [ Html.Attributes.class "mb-4" ]
            [ chaosFactorView model ]
        , fateQuestionUtility model
        ]


chaosFactorView : Model -> Html Msg
chaosFactorView model =
    Html.div [ Html.Attributes.class "flex items-center space-x-4 mb-4" ]
        [ Html.button
            [ Html.Attributes.type_ "button"
            , Html.Events.onClick (UpdateChaosFactor False)
            , Html.Attributes.class "p-2 bg-red-500 text-white rounded"
            ]
            [ Html.text "-" ]
        , Html.div [ Html.Attributes.class "text-xl dark:text-white" ]
            [ Html.text <| "Chaos Factor: " ++ String.fromInt model.chaosFactor ]
        , Html.button
            [ Html.Attributes.type_ "button"
            , Html.Events.onClick (UpdateChaosFactor True)
            , Html.Attributes.class "p-2 bg-green-500 text-white rounded"
            ]
            [ Html.text "+" ]
        , generateRandomEventButton
        , testExpectedSceneButton
        ]


fateQuestionUtility : Model -> Html Msg
fateQuestionUtility model =
    Html.div [ Html.Attributes.class "bg-gray-100 dark:bg-gray-800 p-4 rounded shadow-lg mb-4" ]
        [ Html.h3 [ Html.Attributes.class "text-base font-bold mb-2 dark:text-gray-300" ] [ Html.text "Fate Question" ]
        , Html.div [ Html.Attributes.class "grid grid-cols-3 lg:grid-cols-9 gap-2" ]
            (List.map
                (\odds ->
                    Html.button
                        [ Html.Attributes.type_ "button"
                        , Html.Events.onClick <| AskFateQuestion odds
                        , Html.Attributes.class "p-1 bg-blue-500 text-white rounded text-sm"
                        ]
                        [ Html.text <| FateChart.oddsToString odds ]
                )
                [ FateChart.Certain
                , FateChart.NearlyCertain
                , FateChart.VeryLikely
                , FateChart.Likely
                , FateChart.FiftyFifty
                , FateChart.Unlikely
                , FateChart.VeryUnlikely
                , FateChart.NearlyImpossible
                , FateChart.Impossible
                ]
            )
        ]


generateRandomEventButton : Html Msg
generateRandomEventButton =
    Html.button
        [ Html.Attributes.type_ "button"
        , Html.Events.onClick GenerateRandomEvent
        , Html.Attributes.class "p-2 bg-blue-500 text-white rounded"
        ]
        [ Html.text "Generate Random Event" ]


testExpectedSceneButton : Html Msg
testExpectedSceneButton =
    Html.button
        [ Html.Attributes.type_ "button"
        , Html.Events.onClick TestExpectedScene
        , Html.Attributes.class "p-2 bg-blue-500 text-white rounded"
        ]
        [ Html.text "Text Expected Scene" ]


saveManagementView : Model -> SaveInfo -> Html Msg
saveManagementView model saveInfo =
    Html.div [ Html.Attributes.class "bg-white dark:bg-gray-700 p-4 rounded shadow-lg" ]
        [ Html.h2 [ Html.Attributes.class "text-lg font-bold mb-2 dark:text-gray-300" ] [ Html.text "Save Management" ]
        , Html.button
            [ Html.Attributes.type_ "button"
            , Html.Events.onClick Reset
            , Html.Attributes.class "text-white dark:text-black bg-red-500 dark:bg-red-300 text-xs p-1 rounded-full"
            ]
            [ Html.text "Clear Current State" ]
        , Html.ol [ Html.Attributes.class "list-decimal pl-5 space-y-2" ]
            (List.indexedMap (saveSlotView model) saveInfo.slots)
        ]


saveSlotView : Model -> Int -> Maybe SaveSlotInfo -> Html Msg
saveSlotView model index maybeSlot =
    case maybeSlot of
        Nothing ->
            Html.li [ Html.Attributes.class "p-2 mb-2 bg-gray-200 dark:bg-gray-700 rounded-full text-sm dark:text-white flex justify-between items-center" ]
                [ Html.text "Empty Slot"
                , Html.button
                    [ Html.Attributes.type_ "button"
                    , Html.Events.onClick <| Save (index + 1)
                    , Html.Attributes.class "text-green-500 dark:text-green-300 text-xs ml-auto bg-white dark:bg-gray-500 p-1 rounded-full"
                    ]
                    [ Html.text "Save" ]
                ]

        Just slot ->
            let
                createdAt =
                    slot.createdAt
                        |> Time.millisToPosix
                        |> Strftime.format "%Y-%m-%d %H:%M:%S" (model.timeZone |> Maybe.withDefault Time.utc)
            in
            Html.li [ Html.Attributes.class "p-2 mb-2 bg-gray-200 dark:bg-gray-700 rounded-full text-sm dark:text-white flex justify-between items-center" ]
                [ Html.text <| "Created At: " ++ createdAt
                , Html.div [ Html.Attributes.class "flex space-x-2" ]
                    [ Html.button
                        [ Html.Attributes.type_ "button"
                        , Html.Events.onClick <| InitiateLoad (index + 1)
                        , Html.Attributes.class "text-blue-500 dark:text-blue-300 text-xs bg-white dark:bg-gray-500 p-1 rounded-full"
                        ]
                        [ Html.text "Load" ]
                    , Html.button
                        [ Html.Attributes.type_ "button"
                        , Html.Events.onClick <| Delete (index + 1)
                        , Html.Attributes.class "text-red-500 dark:text-red-300 text-xs bg-white dark:bg-gray-500 p-1 rounded-full"
                        ]
                        [ Html.text "Delete" ]
                    ]
                ]



-- HELPERS


rollOnTable : Table -> Random.Seed -> Msg
rollOnTable table seed =
    let
        ( item1, newSeed ) =
            rollOnItems (Table.items1 table) seed

        ( item2, finalSeed ) =
            rollOnItems (Table.items2 table) newSeed
    in
    RollOnTable (Table.name table) item1 item2 finalSeed


rollOnItems : List String -> Random.Seed -> ( String, Random.Seed )
rollOnItems items seed =
    case items of
        x :: ls ->
            Random.step (Random.uniform x ls) seed

        _ ->
            ( "ERROR: empty table", seed )


onEnterPressed : Msg -> Html.Attribute Msg
onEnterPressed event =
    Html.Events.on "keydown"
        (Decode.map
            (\key ->
                if key == 13 then
                    event

                else
                    NoOp
            )
            Html.Events.keyCode
        )


spawnRandomEvent : Model -> ( String, Random.Seed )
spawnRandomEvent model =
    let
        ( roll, newSeed1 ) =
            Random.step (Random.int 1 100) model.randomSeed

        ( result, newSeed2 ) =
            if roll <= 5 then
                ( "Remote Event", newSeed1 )

            else if roll <= 10 then
                ( "Ambiguous Event", newSeed1 )

            else if roll <= 20 then
                ( "New NPC", newSeed1 )

            else if roll <= 50 then
                let
                    ( ( npc, s ), found ) =
                        case model.charactersList of
                            x :: ls ->
                                ( Random.step (Random.uniform x ls) newSeed1, True )

                            _ ->
                                ( ( "NONE", newSeed1 ), False )
                in
                if found then
                    if roll <= 40 then
                        ( "NPC Action - " ++ npc, s )

                    else if roll <= 45 then
                        ( "NPC Negative - " ++ npc, s )

                    else
                        ( "NPC Positive - " ++ npc, s )

                else
                    ( "Current Context", s )

            else if roll <= 70 then
                let
                    ( ( thread, s ), found ) =
                        case model.threadsList of
                            x :: ls ->
                                ( Random.step (Random.uniform x ls) newSeed1, True )

                            _ ->
                                ( ( "NONE", newSeed1 ), False )
                in
                if found then
                    if roll <= 55 then
                        ( "Move Toward A Thread - " ++ thread, s )

                    else if roll <= 65 then
                        ( "Move Away From A Thread - " ++ thread, s )

                    else
                        ( "Close A Thread - " ++ thread, s )

                else
                    ( "Current Context", s )

            else if roll <= 80 then
                ( "PC Negative", newSeed1 )

            else if roll <= 85 then
                ( "PC Positive", newSeed1 )

            else
                ( "Current Context", newSeed1 )
    in
    ( result, newSeed2 )


eventEncoder : Event -> Encode.Value
eventEncoder event =
    Encode.object
        [ ( "eventType", Encode.string event.eventType )
        , ( "value", Encode.string event.value )
        ]


eventDecoder : Decoder Event
eventDecoder =
    Decode.succeed Event
        |> required "eventType" Decode.string
        |> required "value" Decode.string


modelEncoder : Model -> Encode.Value
modelEncoder model =
    Encode.object
        [ ( "eventLog", Encode.list eventEncoder model.eventLog )
        , ( "threadsList", Encode.list Encode.string model.threadsList )
        , ( "charactersList", Encode.list Encode.string model.charactersList )
        , ( "chaosFactor", Encode.int model.chaosFactor )
        ]


type alias PartialModel =
    { eventLog : List Event
    , threadsList : List String
    , charactersList : List String
    , chaosFactor : Int
    }


modelDecoder : Model -> Decoder Model
modelDecoder model =
    (Decode.succeed PartialModel
        |> required "eventLog" (Decode.list eventDecoder)
        |> required "threadsList" (Decode.list Decode.string)
        |> required "charactersList" (Decode.list Decode.string)
        |> required "chaosFactor" Decode.int
    )
        |> Decode.map
            (\partial ->
                { model
                    | eventLog = partial.eventLog
                    , threadsList = partial.threadsList
                    , charactersList = partial.charactersList
                    , chaosFactor = partial.chaosFactor
                }
            )


saveInfoDecoder : Decode.Decoder SaveInfo
saveInfoDecoder =
    Decode.map SaveInfo
        (Decode.field "slots" (Decode.list (Decode.nullable saveSlotInfoDecoder)))


saveSlotInfoDecoder : Decode.Decoder SaveSlotInfo
saveSlotInfoDecoder =
    Decode.map SaveSlotInfo
        (Decode.field "createdAt" Decode.int)
